import request from '@/utils/request'
// 查询意见反馈分页
export function pageFeedback (query) {
  return request({
    url: '/biz/app-feedback/page',
    method: 'get',
    params: query
  })
}

// 查询意见反馈详细
export function getFeedback (data) {
  return request({
    url: '/biz/app-feedback/info',
    method: 'get',
    params: data
  })
}
// 处理意见反馈
export function updateFeedback (data) {
  return request({
    url: '/biz/app-feedback/cl',
    method: 'post',
    data: data
  })
}
// 删除意见反馈
export function delFeedback (data) {
  return request({
    url: '/biz/app-feedback/del',
    method: 'post',
    data: data
  })
}
