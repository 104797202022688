<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="vertical">
          <a-row :gutter="48">
            <a-col
              :md="6"
              :sm="24"
            >
              <a-form-item label="处理状态">
                <a-select
                  v-model="queryParam.processStatus"
                  style="width: 100%"
                  placeholder="请选择"
                >
                  <a-select-option :value="1">待处理</a-select-option>
                  <a-select-option :value="2">已处理</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :md="6"
              :sm="24"
            >
              <span class="table-page-search-submitButtons">
                <a-button
                  type="primary"
                  @click="handleQuery"
                ><a-icon type="search" />查询</a-button>
                <a-button
                  style="margin-left: 8px;margin-top:28px;"
                  @click="resetQuery"
                ><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span
          slot="type"
          slot-scope="text, record"
        >
          {{ record.type == 1 ? '下载问题' :
            record.type == 2 ? '会员问题' : record.type == 3 ? '章节/图片问题' : '其他问题'
          }}
        </span>
        <span
          slot="processStatus"
          slot-scope="text, record"
        >
          {{ record.processStatus === 1 ? '待处理' : '已处理' }}
        </span>
        <div
          slot="imgUrls"
          slot-scope="text, record"
        >
          <div v-if="record.imgUrls">
            <img
              v-for="i in JSON.parse(record.imgUrls)"
              :key="i"
              style="width:50px;height;:50px"
              :src="i"
              v-viewer
            />
          </div>
          <div v-else>--</div>
        </div>
        <span
          slot="createTime"
          slot-scope="text, record"
        >
          {{ parseTime(record.createTime) }}
        </span>
        <span
          slot="operation"
          slot-scope="text, record"
        >
          <a
            @click="handleEdit(record)"
            v-if="record.processStatus === 1"
          >处理</a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

      <a-modal
        title="回复"
        :visible="visible"
        @ok="handleOk"
        @cancel="visible = false"
      >
        <a-input
          v-model="dataForm.processResult"
          placeholder="请输入回复内容"
        ></a-input>
      </a-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageFeedback, updateFeedback, delFeedback } from '@/api/project/feedback'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Site',
  mixins: [tableMixin],
  data () {
    return {
      deviceTypeList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        processStatus: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        { title: '反馈类型', scopedSlots: { customRender: 'type' }, ellipsis: true, align: 'center' },
        { title: '反馈内容', dataIndex: 'content', ellipsis: true, align: 'center' },
        { title: '反馈图片', scopedSlots: { customRender: 'imgUrls' }, ellipsis: true, align: 'center' },
        { title: '处理状态', scopedSlots: { customRender: 'processStatus' }, ellipsis: true, align: 'center' },
        { title: '回复内容', dataIndex: 'processResult', ellipsis: true, align: 'center' },
        { title: '创建时间', scopedSlots: { customRender: 'createTime' }, width: 150, ellipsis: true, align: 'center' },
        { title: '操作', dataIndex: 'operation', width: 150, scopedSlots: { customRender: 'operation' }, align: 'center' }
      ],
      visible: false,
      dataForm: {}
    }
  },
  created () {
    this.getList()
  },
  methods: {
    /** 查询设备信息列表 */
    getList () {
      this.loading = true
      pageFeedback(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
      }).finally(() => {
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleEdit (info) {
      this.dataForm = {
        processResult: '您的反馈已收到，我们会尽快处理',
        id: info.id
      }
      this.visible = true
    },
    handleOk () {
      updateFeedback(this.dataForm).then(res => {
        this.visible = false
        this.getList()
      })
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      this.$confirm({
        title: '提示',
        content: '确认要删除所选中数据?',
        onOk () {
          return delFeedback({ id: row.id }).then(() => {
            that.getList()
            that.$message.success('操作成功', 3)
          })
        },
        onCancel () { }
      })
    }
  }
}
</script>
@/api/valve/deviceType
